import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Navbar from '../Components/Navbar'
import IntestazioneMain from "../Components/IntestazioneMain";
import Container from '../Components/Container';
import Footer from "../Components/Footer";
import Button from "../Components/Button";

import ReactGA from "react-ga4";

const Sostienici = () => {
    useEffect(() => {
        document.title = 'Sostienici'
    }, [])

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
            title: "Sostienici"
        });
    });

    return (
        <div>
            <Navbar />
            <main id='main' style={{ marginTop: '120px' }}>
                <IntestazioneMain
                    titolo="SOSTIENICI"
                    sottotitolo="IDEEINSCENA"
                    testo={`Sostenendo le attività di Ideeinscena puoi contribuisce a diffondere cultura, socialità, solidarietà ma anche allegria e divertimento.\nCi sono numerosi e svariati modi per sostenere le attività di Ideeinscena.`}
                    linkAbilitato={false}
                    testoBottone="Scopri di più"
                    linkTo="/supporto"
                />

                <Container
                    classname={"container custom-box"}
                    showImage={true}
                    imgSrc={'https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/5per1000.jpg?alt=media&token=9b188232-89c1-4c6a-91d8-b180f10776e2'}
                    titolo={"SOSTIENICI CON IL TUO 5X1000"}
                    testo={`
                        Ogni anno arriva il momento in cui nella dichiarazione dei redditi viene offerta la possibilità di scegliere a chi destinare il proprio 5x1000.
                        Questo semplice gesto, completamente gratuito, è prezioso per consentire a Ideeinscena, associazione di promozione sociale, di continuare a proporre cultura.
                        Se scegli di destinare il tuo 5x1000 a Ideeinscena, ci consentirai di realizzare eventi teatrali e musicali, rassegne letterarie e proposte per il territorio, anche in ambito scolastico; ci consentirai di sviluppare meglio sinergie e collaborazioni, con l’obiettivo di… immaginare e creare il futuro.
                        Basta apporre una firma nell’apposito quadro della dichiarazione, scrivendo anche il nostro codice fiscale 91138010151.
                    `}
                    linkAbilitato={false}
                    testoBottone={'Vai alla pagina'}
                    navigateTo={'/spettacoli'}
                />

                <div className="container mt-4">
                    <div className="row justify-content-between">
                        <div className="col-md-5 p-4">
                            <div className="container custom-box p-4">
                                <h4 className="mb-4">EROGAZIONE LIBERALE</h4>
                                <p>Una delle modalità più semplice per sostenerci è effettuare un’erogazione liberale tramite un bonifico bancario.</p>
                                <p>Conto corrente bancario intestato a queste coordinate: </p>

                                <ul>
                                    <li><strong>Intestatario:</strong> IDEEINSCENA APS</li>
                                    <li><strong>Banca:</strong> BANCA DI CREDITO COOPERATIVO DI BARLASSINA-SOCIETA' COOPERATIVA
                                        – filiale di CESANO MADERNO</li>
                                    <li><strong>Causale:</strong> Erogazione liberale</li>
                                    <li><strong>Codice IBAN:</strong> IT14 B083 7432 9100 0000 3089 362</li>
                                </ul>
                                <br />
                                <p>
                                    Potrai beneficiare delle detrazioni/deduzioni Irpef* della tua dichiarazione dei redditi e al tempo stesso sostenere le attività culturali nella tua città.
                                    *Con la riforma del Terzo settore operata dal DLgs. 117/2017 hanno debuttato nel mod. Redditi 2019 PF delle nuove detrazioni/deduzioni Irpef riguardanti le erogazioni liberali a Onlus Aps e Odv.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5 p-4">
                            <div className="container custom-box p-4">
                                <h4 className="mb-4">CONTATTACI</h4>
                                <p>Per avere maggiori informazioni o per definire particolari modalità con cui sostenerci, ti invitiamo a contattarci.</p>
                                <Button
                                    linkAbilitato={true}
                                    linkTo={'/contatti'}
                                    testoBottone={'Vai ai contatti'}
                                />
                            </div>
                            <div className="mt-4 text-center">
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Loghi%2Flogo1.png?alt=media&token=1745a219-00e0-444e-85a5-88a7c3ab7749"
                                    alt="logoIIS"
                                    className="img-fluid rounded"
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </main>
            <br /><br /><br />
            <Footer />
        </div>
    )
}

export default Sostienici