import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from './Button';

const Intestazione = ({ titolo, sottotitolo, testo1, testo2, testo3, linkAbilitato, testoBottone, linkTo }) => {
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    const titleFontSize = isTablet ? '40px' : '24px'; 
    const fontSize = isTablet ? '30px' : '16px';

    return (
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2 style={{ titleFontSize }}>{sottotitolo}</h2>
                    <p style={{ titleFontSize }}>{titolo}</p>
                </div>

                <div className="row content">
                    <div className="col-lg-6">
                        <p style={{ fontSize }}>{testo1}</p>
                        <p style={{ fontSize }}>{testo2}</p>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0">
                        <p style={{ fontSize }}>{testo3}</p>
                        
                        <Button 
                            linkAbilitato={linkAbilitato}
                            testoBottone={testoBottone}
                            linkTo={linkTo}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Intestazione;
