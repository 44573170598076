import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Config/firebaseConfig';

const Footer = () => {
    const [dataFooter, setDataFooter] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const docRef = doc(db, 'Elementi', 'Footer');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setDataFooter(data);
            }
            else
                console.error('Documento non trovato!');
        };

        fetchData();
    }, []);

    if (!dataFooter)
        return <div>Caricamento...</div>;

    const logoUrl = dataFooter?.logoUrl || 'default-logo-url.jpg';
    const pages = Array.isArray(dataFooter?.pagine) ? dataFooter.pagine : [];
    const spettacoli = Array.isArray(dataFooter?.spettacoli) ? dataFooter.spettacoli : [];
    const contacts = dataFooter?.contatti || {};
    const socials = Array.isArray(dataFooter?.social) ? dataFooter.social : [];

    return (
        <footer id='footer'>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 footer-info">
                            <img src={logoUrl} alt="WorkSpace Logo" style={{ width: '70%', height: 'auto' }} />
                        </div>
                        <div className="col-lg-2 col-md-6 footer-links">
                            <h4>Pagine</h4>
                            <ul>
                                {pages.length > 0 ? pages.map((page, index) => (
                                    <li key={index}>
                                        <i className="bx bx-chevron-right"></i>
                                        <Link to={page.path}>{page.name}</Link>
                                    </li>
                                )) : (
                                    <li><i className="bx bx-chevron-right"></i> Nessuna pagina disponibile</li>
                                )}
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>I nostri Spettacoli</h4>
                            <ul>
                                {spettacoli.length > 0 ? spettacoli.map((spettacolo, index) => (
                                    <li key={index}>
                                        <i className="bx bx-chevron-right"></i>
                                        <Link to={`/spettacolo/${spettacolo.id}`}>{spettacolo.nome}</Link>
                                    </li>
                                )) : (
                                    <li><i className="bx bx-chevron-right"></i> Nessun spettacolo disponibile</li>
                                )}
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-newsletter">
                            <p>
                                <strong>Indirizzo:</strong> {contacts.indirizzo}<br />
                                <strong>Telefono:</strong> {contacts.telefono}<br />
                                <strong>Email:</strong> {contacts.email}<br />
                            </p>
                            <div className="social-links mt-3">
                                {socials.length > 0 ? socials.map((social, index) => (
                                    <a
                                        key={index}
                                        href={social.url}
                                        className={social.name.toLowerCase()}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className={social.icon}></i>
                                    </a>
                                )) : (
                                    <p>Nessun social disponibile</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>IdeeInScena</span></strong>. All Rights Reserved
                </div>

                <div className="credits" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul style={{ display: "flex", listStyle: "none", padding: 0, margin: "10px 0" }}>
                        <li style={{ marginRight: "15px" }}>
                            <i className="bx bx-chevron-right"></i>
                            <a
                                href="https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Policy%2F_COOKIE%20POLICY.pdf?alt=media&token=806fe670-c185-4b3e-8b1e-eb6fd7084813"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Cookie Policy
                            </a>
                        </li>
                        <li style={{ marginRight: "15px" }}>
                            <i className="bx bx-chevron-right"></i>
                            <a
                                href="https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Policy%2F_INFORMATIVA%20RACCOLTA%20DATI%20TRAMITE%20SITO.pdf?alt=media&token=53ce7393-282a-41f2-a45d-274a330c366d"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Informativa Dati
                            </a>
                        </li>
                        <li>
                            <i className="bx bx-chevron-right"></i>
                            <a
                                href="https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Policy%2F_PRIVACY%20POLICY.pdf?alt=media&token=6de742f8-24c6-4042-b163-e318e276b18e"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>
                        </li>
                    </ul>
                </div>


                <div className="credits">
                    Designed by Giacomo Barzaghi
                </div>
            </div>
        </footer>
    );
};

export default Footer;