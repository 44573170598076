import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../Config/firebaseConfig';

import Navbar from '../Components/Navbar';
import Intestazione from '../Components/Intestazione';
import EventoCard from '../Components/EventoCard';
import EventoSidebar from '../Components/EventoSidebar';
import BackupButton from '../Components/BackupButton';
import Footer from '../Components/Footer';

import ReactGA from "react-ga4";

const EventiCulturali = () => {
    const [eventi, setEventi] = useState([]);
    const [spettacoli, setSpettacoli] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const eventsPerPage = 5;

    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    useEffect(() => {
        const fetchEventi = async () => {
            try {
                const eventiCollection = collection(db, 'Eventi');
                const eventiQuery = query(eventiCollection, where('isBozza', '==', false));
                const eventiSnapshot = await getDocs(eventiQuery);

                const eventiList = eventiSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                
                eventiList.sort((a, b) => Number(b.id) - Number(a.id));
                setEventi(eventiList);
            } catch (error) {
                console.error("Error fetching eventi:", error);
            }
        };
        fetchEventi();
        document.title = 'Eventi Culturali';
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const spettacoliCardCol = collection(db, 'SpettacoliCard');
            const spettacoliCardSnapshot = await getDocs(spettacoliCardCol);
            const spettacoliCardList = spettacoliCardSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setSpettacoli(spettacoliCardList);
        };
        fetchData();
    }, []);

    const indexOfLastEvent = currentPage * eventsPerPage;
    const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
    const currentEvents = eventi.slice(indexOfFirstEvent, indexOfLastEvent);

    const totalPages = Math.ceil(eventi.length / eventsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
            window.scrollTo(0, 0); 
        }
    };
    
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
            window.scrollTo(0, 0);
        }
    };


    const location = useLocation();
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
            title: "Eventi culturali",
        });
    });

    return (
        <div>
            <Navbar />
            <header id="header">
                <Intestazione
                    titolo="EVENTI CULTURALI"
                    sottotitolo="IDEEINSCENA"
                    testo1='La compagnia teatrale IDEEinSCENA nasce dal desiderio di valorizzare la passione per il teatro, ma c’è di più......'
                    testo2='C’è una prospettiva, ovvero quella di stimolare e sostenere la crescita morale, culturale e sociale dell’individuo, attraverso ogni espressione di arte, promuovendone la diffusione in ogni sua forma'
                    testo3='La compagnia propone uno o più testi per ogni stagione e partecipa a rassegne teatrali. La compagnia appoggia le svariate iniziative promosse a sostegno di realtà di volontariato e di impegno sociale e culturale.'
                    linkAbilitato={false}
                />
            </header>
            <main>
                <section id="blog" className="blog">
                    <div className="container" data-aos="fade-up">
                        <div className="row">
                            <div className="col-lg-8 entries">
                                {currentEvents.length > 0 ? (
                                    currentEvents.map(evento => (
                                        <EventoCard
                                            key={evento.id}
                                            imgSrc={evento.LinkImg}
                                            Titolo={evento.TitoloCopertina}
                                            Autore={evento.Autore}
                                            Data={evento.Data}
                                            link={`/evento/${evento.id}`}
                                        />
                                    ))
                                ) : (
                                    <article className="entry no-results">
                                        <h2 className="entry-title">
                                            <h3>Nessun risultato trovato</h3>
                                        </h2>
                                        <div className="entry-content">
                                            <p>Non ci sono articoli disponibili in questo momento. Per favore, torna più tardi.</p>
                                        </div>
                                    </article>
                                )}
                                <div className="pagination">
                                    <button className="pagination-btn" onClick={handlePrevPage} disabled={currentPage === 1}>
                                        <span className="arrow left"></span>
                                    </button>
                                    <span className="pagination-info">Pagina {currentPage} di {totalPages}</span>
                                    <button className="pagination-btn" onClick={handleNextPage} disabled={currentPage === totalPages}>
                                        <span className="arrow right"></span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                {!isTablet && <EventoSidebar spettacoli={spettacoli} />}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {!isTablet && <BackupButton />} 
            <Footer />
        </div>
    );
};

export default EventiCulturali;
