import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

import ReactGA from "react-ga4";

const Policy = () => {
    useEffect(() => {
        document.title = 'Privacy Policy';
    }, []);

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
            title: "Privacy Policy",
        });
    });

    return (
        <div>
            <Navbar />
            <main class="main-with-margin">
                <section class="py-5">
                    <div class="container">
                        <h2 class="text-center mb-4">PRIVACY POLICY</h2>
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    In questa pagina si descrivono le modalità di gestione del sito in riferimento al
                                    trattamento dei dati personali degli utenti che lo consultano. Si tratta di un’informativa
                                    che è resa ai sensi dell'art. 13 del Regolamento UE 2016/679 (c.d. GDPR) a coloro che
                                    interagiscono con i servizi web della Associazione di Promozione Sociale IdeeinScena (oppure
                                    IdeeinScena Aps) accessibili per via telematica dall’indirizzo: www.ideeinscena.com. Si
                                    informa che le indicazioni riportate nel presente sito possono essere soggette a variazione,
                                    al fine di essere sempre aggiornate alla normativa vigente. Si consiglia pertanto di
                                    prendere periodicamente visione dell’informativa.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="py-5">
                    <div class="container">
                        <div class="container">
                            <h2 class="text-left mb-4">1. Tipologia di dati trattati</h2>
                            <p>I dati oggetto del trattamento sono i dati di navigazione e i dati forniti volontariamente
                                dall'utente, come di
                                seguito definiti.</p>
                            <section>
                                <h3>Dati di navigazione</h3>
                                <p>I sistemi informatici e le procedure software preposte al funzionamento di questo sito web
                                    acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui
                                    trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet. Si tratta di
                                    informazioni che non sono raccolte per essere associate a interessati identificati, ma che
                                    per loro stessa natura, potrebbero, attraverso elaborazioni ed associazioni con dati
                                    detenuti da terzi, permettere di identificare gli utenti.
                                </p>
                                <p>In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer
                                    utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform
                                    Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato
                                    nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il
                                    codice numerico indicante lo stato della risposta data al server ed altri parametri relativi
                                    al sistema operativo e all’ambiente informatico dell’utente.</p>
                                <p>Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime
                                    sull’uso del sito e per controllarne il corretto funzionamento e vengono cancellati
                                    immediatamente dopo l’elaborazione.</p>
                            </section>

                            <section>
                                <h3>Dati forniti volontariamente dall’utente</h3>
                                <p>L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati nel
                                    sito, comporta la successiva acquisizione dell’indirizzo del mittente, necessario per
                                    rispondere alle richieste, nonché degli altri eventuali dati personali contenuti nella
                                    missiva. Specifiche eventuali informative di sintesi vengono riportate o visualizzate nelle
                                    pagine del Sito predisposte per i servizi a richiesta, in modo tale da richiamare
                                    l’attenzione dell’interessato sul trattamento dei suoi dati personali.</p>
                            </section>
                        </div>
                    </div>
                </section>
                <section class="py-5">
                    <div class="container">
                        <div class="container">
                            <h2 class="text-left mb-4">2. Finalità del trattamento</h2>
                            <section>
                                <h3>Finalità del trattamento</h3>
                                <p>Il trattamento dei dati è diretto esclusivamente al raggiungimento delle seguenti finalità:
                                </p>
                                <ul>
                                    <li>finalità connesse all'utilizzo dei servizi offerti per la navigazione dal sito;</li>
                                    <li>attività connesse e strumentali alla gestione delle richieste di contatto;</li>
                                    <li>accesso a servizi quali newsletter, consultazione materiali, mailing list, iscrizione ad
                                        eventi, aree riservate, download, nonché gestione di operazioni afferenti e conseguenti
                                        alla/e donazione/i, informazioni su iniziative, attività e progetti riservati ai
                                        sostenitori ed ai donatori, anche potenziali, in formato cartaceo e digitale, richieste
                                        di adesione a campagne di raccolta fondi, sondaggi, petizioni;</li>
                                    <li>trattare i dati relativi a curriculum per la valutazione delle candidature lavorative o
                                        dati relativi a proposte di collaborazione.</li>
                                </ul>
                                <p>Il conferimento dei dati per le finalità di cui alle lettere c) e d) è facoltativo e va
                                    prestato secondo le modalità di cui all'art. 7 del GDPR. Le comunicazioni relative
                                    all’attività di marketing potranno avvenire mediante l’utilizzo di modalità tradizionali
                                    (es: posta cartacea, telefonate) e telematiche (es: e-mail).</p>
                            </section>
                        </div>
                    </div>
                </section>
                <section class="py-5">
                    <div class="container">
                        <div class="container">
                            <h2 class="text-left mb-4">3. Modalità di trattamento</h2>
                            <section>
                                <h3>Modalità di trattamento</h3>
                                <p>Il trattamento dei dati potrà consistere, oltre che nella loro raccolta, nella loro
                                    registrazione, organizzazione, conservazione, consultazione, elaborazione, modificazione,
                                    selezione, estrazione, raffronto, utilizzo, interconnessione, blocco, comunicazione,
                                    cancellazione e distruzione, secondo quanto previsto dall’art. 4 n. 2) del GDPR. Potrà
                                    essere effettuato sia con l’utilizzo di supporto cartaceo, sia con l’ausilio di strumenti
                                    elettronici, informatici e telematici, secondo modalità e con strumenti idonei a garantire
                                    la sicurezza e la riservatezza dei dati stessi. In particolare, saranno adottate tutte le
                                    misure tecniche e organizzative adeguate alla protezione dei dati ai fini di soddisfare i
                                    requisiti di legge e di tutelare i diritti degli interessati.</p>
                                <p>Il trattamento viene effettuato attraverso sistemi automatizzati per un periodo strettamente
                                    necessario a conseguire gli scopi per cui sono stati raccolti e comunque, in conformità alle
                                    disposizioni legislative vigenti. In particolare, i Suoi dati saranno conservati nel
                                    rispetto del principio di proporzionalità e salvo Sua revoca del consenso o specifica
                                    necessità per adempiere ad obbligo di legge, fino a che non siano state perseguite le
                                    finalità del trattamento. I Dati saranno quindi conservati per le finalità relative le
                                    donazioni, nel corso del periodo in cui sosterrai l’Associazione di Promozione Sociale
                                    IdeeinScena e sino ai dieci anni successivi l’ultima donazione, anche atteso l’obbligo di
                                    conservazione ex lege delle scritture. Per quanto attiene le attività informative, di
                                    raccolta fondi e di profilazione, i Suoi Dati saranno conservati fintanto che sosterrai
                                    l’Associazione di Promozione Sociale IdeeinScena e successivamente per il periodo di 10 anni
                                    atteso l’interesse l’Associazione di Promozione Sociale IdeeinScena a ricevere sostegno
                                    economico e condivisione alle proprie iniziative da parte di tutte le persone disponibili a
                                    supportare le nostre attività.</p>
                            </section>
                        </div>
                    </div>
                </section>
                <section class="py-5">
                    <div class="container">
                        <div class="container">
                            <h2 class="text-left mb-4">4. Comunicazione e diffusione dei dati</h2>
                            <section>
                                <h3>Comunicazione e diffusione dei dati</h3>
                                <p>I dati non saranno diffusi e verranno trattati dalle persone autorizzate al trattamento dei
                                    dati in funzione delle mansioni svolte e adeguatamente istruite. I dati potranno essere
                                    comunicati a soggetti esterni tra cui anche i Responsabili nominati ai sensi dell’art. 28
                                    del GDPR. In particolare, i dati potranno essere comunicati alle seguenti categorie di
                                    soggetti, di seguito elencate a titolo esemplificativo e non esaustivo: istituti bancari e
                                    società specializzate nella gestione dei pagamenti e nell’assicurazione del credito, studi
                                    legali e di consulenza, pubbliche autorità o amministrazioni per gli adempimenti di legge,
                                    fornitori italiani ed esteri, società di finanziamento.</p>
                                <p>Si precisa che un elenco dettagliato dei Responsabili del trattamento è disponibile presso la
                                    sede legale di via Conciliazione, n. 15.</p>
                                <p>Per tutte le finalità indicate nella presente informativa i Vostri dati potranno essere
                                    comunicati anche all’estero, all’interno e all’esterno dell’Unione Europea, nel rispetto dei
                                    diritti e delle garanzie previsti dalla normativa vigente, previa verifica che il Paese in
                                    questione garantisca un livello di protezione adeguato ai sensi di quanto previsto dal GDPR.
                                </p>
                            </section>
                        </div>
                    </div>
                </section>
                <section class="py-5">
                    <div class="container">
                        <div class="container">
                            <h2 class="text-left mb-4">5. Diritti dell’interessato</h2>
                            <section>
                                <h3>Diritti dell’interessato</h3>
                                <p>In relazione ai predetti dati potranno essere esercitati tutti i diritti di cui all’art. 7
                                    del Codice Privacy e degli artt. 15, 16, 17, 18, 20 e 21 del GDPR, e nello specifico:</p>
                                <ul>
                                    <li>a) il diritto di accesso ai dati personali;</li>
                                    <li>b) la loro rettifica in caso di inesattezza;</li>
                                    <li>c) la cancellazione dei dati;</li>
                                    <li>d) la limitazione al trattamento;</li>
                                    <li>e) l’opposizione al trattamento;</li>
                                    <li>f) il diritto alla portabilità dei dati, ossia di ricevere in formato strutturato, di
                                        uso comune e leggibile da dispositivo automatico, i dati personali forniti e di
                                        ottenerne il trasferimento presso un altro Titolare del trattamento senza impedimenti.
                                    </li>
                                </ul>
                                <p>In caso di violazione delle presenti disposizioni, l’interessato al trattamento ha il diritto
                                    di proporre reclamo presso la competente Autorità di Controllo.</p>
                                <p>Per ulteriori delucidazioni in merito alla presente informativa o sulla tematica privacy,
                                    ovvero per esercitare i diritti o revocare il consenso, potrete scrivere a
                                    ideeinscenadesio@gmail.com.</p>
                            </section>
                        </div>
                    </div>
                </section>
                <section class="py-5">
                    <div class="container">
                        <div class="container">
                            <h2 class="text-left mb-4">6. Revoca del consenso</h2>
                            <section>
                                <h3>Revoca del consenso</h3>
                                <p>Il consenso prestato in ordine al trattamento dei propri dati personali potrà essere revocato
                                    in qualsiasi momento, senza che ciò pregiudichi la liceità del trattamento dei dati
                                    effettuato nel periodo antecedente alla revoca stessa, ovvero ulteriori trattamenti fondati
                                    su basi giuridiche diverse rispetto al consenso.</p>
                            </section>
                        </div>
                    </div>
                </section>
                <section class="py-5">
                    <div class="container">
                        <div class="container">
                            <h2 class="text-left mb-4">7. Titolare e Responsabile del Trattamento</h2>
                            <section>
                                <h3>Titolare del trattamento</h3>
                                <p>Il titolare del trattamento è IdeeinScena Aps.</p>
                            </section>
                            <section>
                                <h3>Responsabile del trattamento o Designato</h3>
                                <p>Il responsabile del trattamento o designato è Arienti Roberta Nerina.</p>
                            </section>
                        </div>
                    </div>
                </section>
                <section class="py-5">
                    <div class="container">
                        <p>Desio, 23/09/2022</p>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default Policy;
