import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from './Button';

const IntestazioneMain = ({ titolo, sottotitolo, testo, linkAbilitato, testoBottone, linkTo }) => {
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    const titleFontSize = isTablet ? '40px' : '24px';
    const fontSize = isTablet ? '30px' : '16px';

    return (
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2 style={{ titleFontSize }}>{sottotitolo}</h2>
                    <p style={{ titleFontSize }}>{titolo}</p>
                </div>

                <div className="content text-left">
                    <p style={{ fontSize }}>
                        {testo.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </p>

                    <div style={{ textAlign: 'right' }}>
                        <Button
                            linkAbilitato={linkAbilitato}
                            testoBottone={testoBottone}
                            linkTo={linkTo}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IntestazioneMain;
