import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Navbar from '../Components/Navbar';
import Intestazione from '../Components/Intestazione';
import Footer from '../Components/Footer';

import ReactGA from "react-ga4";

const NewsLetter = () => {
    useEffect(() => {
        document.title = 'NewsLetter';
    }, []);

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
            title: "NewsLetter",
        });
    });

    return (
        <div>
            <Navbar />
            <header id="header">
                <Intestazione titolo="CONTATTACI" sottotitolo="IDEEINSCENA" />
            </header>
            <main id="main" style={{ marginTop: '-80px' }}>
                <section id="contact" className="contact">
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-lg-4">
                                <div>
                                    <h4>Iscriviti!</h4>
                                    <p>
                                        Lascia qui i tuoi dati con il numero telefonico e/o l’indirizzo e-mail.
                                        Potrai ricevere comodamente tutte le informazioni sulle proposte culturali di
                                        Ideeinscena.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-8 mt-5 mt-lg-0">
                                <iframe
                                    src="https://docs.google.com/forms/d/e/1FAIpQLScoF9acu-t2oDB_lKpjKSxxxINhMkP9ZsyUzCkGBPjSrN_iRg/viewform?embedded=true"
                                    style={{ width: '100%', height: '1200px' }} 
                                    title="Modulo di iscrizione alla newsletter"
                                >
                                    Caricamento…
                                </iframe>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default NewsLetter;
