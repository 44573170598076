import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import MainSidebar from '../Components/MainSidebar';

import ReactGA from 'react-ga4';

const Workspace = () => {
    useEffect(() => {
        document.title = 'Workspace';
    }, []);

    // Definiamo i breakpoint per i dispositivi mobili e tablet
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    // Stampa nel console per verificare il valore di isMobile e isTablet
    console.log('Is mobile:', isMobile);
    console.log('Is tablet:', isTablet);

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: 'Workspace',
        });
    });

    return (
        <div style={{
            padding: isMobile ? '10px' : '20px',
            backgroundColor: isMobile ? '#f0f0f0' : '#ffffff',
            fontSize: isMobile ? '14px' : '16px',
            transition: 'all 0.3s ease', // Aggiungi transizione per vedere i cambiamenti in tempo reale
        }}>
            {isMobile ? (
                <p>Benvenuto nella versione mobile del Workspace!</p>
            ) : (
                <p>Benvenuto nella versione desktop del Workspace!</p>
            )}

            {/* Non mostrare MainSidebar sui dispositivi tablet */}
            {!isTablet && <MainSidebar />}
        </div>
    );
};

export default Workspace;
