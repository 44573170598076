import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ReactGA from "react-ga4";

import Container from '../Components/Container';

const NotFound = () => {
  useEffect(() => {
    document.title = 'Pagina non trovata'
  }, [])

  const location = useLocation();
  useEffect(() => {
      ReactGA.send({
          hitType: "pageview",
          page: location.pathname,
          title: "Not Found",
      });
  });

  return (
    <div>
      <main class="container">
        <Container
          classname={"p-4 custom-box"}
          titolo={"404 - Pagina non trovata"}
          testo={"La pagina che stai cercando non esiste."}
          imgSrc={"https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Loghi%2Flogo1.png?alt=media&token=1745a219-00e0-444e-85a5-88a7c3ab7749"}
          linkAbilitato={true}
          testoBottone={'Vai alla Home'}
          navigateTo={'/'}
        />

      </main>
    </div>
  );
};

export default NotFound;
