import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import { db, storage } from '../Config/firebaseConfig';
import { collection, getDocs } from "firebase/firestore";
import { ref, listAll, getDownloadURL } from "firebase/storage";

import Navbar from '../Components/Navbar';
import Intestazione from '../Components/Intestazione';
import IntestazioneMain from '../Components/IntestazioneMain';
import Container from '../Components/Container';
import MainSidebar from '../Components/MainSidebar';
import ImageGallery from '../Components/ImageGallery';
import BackupButton from '../Components/BackupButton';
import Footer from '../Components/Footer';

import ReactGA from "react-ga4";

const MainPage = () => {
    // Media query per gestione layout responsive
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const rowClass = isTablet ? 'row g-12' : 'row g-8';
    const colClass = isTablet ? 'col-md-12' : 'col-md-8';

    const [containers, setContainers] = useState([]);
    const [galleries, setGalleries] = useState([]);

    // Imposta il titolo della pagina
    useEffect(() => {
        document.title = 'Home - IdeeInScena';
    }, []);

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
            title: "Home - IdeeInScena",
        });
    });

    // Funzione per recuperare i container da Firestore
    useEffect(() => {
        const fetchContainers = async () => {
            try {
                const containersRef = collection(db, "Elementi", "ElementiMain", "Container");
                const containerDocs = await getDocs(containersRef);

                const containerData = containerDocs.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setContainers(containerData);
            } catch (error) {
                console.error("Errore nel recupero dei container:", error);
            }
        };

        fetchContainers();
    }, []);

    // Funzione per recuperare le gallerie da Firestore e Firebase Storage
    useEffect(() => {
        const fetchGalleries = async () => {
            try {
                const galleriesRef = collection(db, "Elementi", "ElementiMain", "Gallerie");
                const galleryDocs = await getDocs(galleriesRef);

                const galleryData = await Promise.all(
                    galleryDocs.docs.map(async (doc) => {
                        const data = doc.data();
                        const { Titolo, Sottotitolo, Testo, Folder, createdAt } = data;

                        // Recupera i link delle immagini dalla cartella nel Firebase Storage
                        const folderRef = ref(storage, Folder);
                        const result = await listAll(folderRef);

                        const imageLinks = await Promise.all(
                            result.items.map((item) => getDownloadURL(item))
                        );

                        return {
                            id: doc.id,
                            title: Titolo,
                            subtitle: Sottotitolo,
                            text: Testo,
                            imageLinks,
                            createdAt: createdAt || 0,
                        };
                    })
                );

                // Ordina le gallerie in ordine decrescente (più recente prima)
                galleryData.sort((a, b) => b.createdAt - a.createdAt);
                setGalleries(galleryData);
            } catch (error) {
                console.error("Errore nel recupero delle gallerie:", error);
            }
        };

        fetchGalleries();
    }, []);

    return (
        <div>
            <Navbar />
            <header className="header-with-margin">
                <div
                    className="background-image"
                    style={{
                        backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Loghi%2Fsalateatro.jpg?alt=media&token=5aa8665f-e958-4a80-9f1e-fbebc829c5f0")`,
                    }}
                ></div>
                <img
                    src="https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/Loghi%2FlogoHeader.png?alt=media&token=8552488c-e2c6-4be3-b869-3b70338cbe86"
                    alt="logo Idee in Scena"
                    className="imgHeader"
                />
            </header>
            <main className='main-content'>
                <IntestazioneMain
                    titolo="CHI SIAMO"
                    sottotitolo="IDEEINSCENA"
                    testo='La compagnia teatrale IDEEINSCENA nasce dal desiderio di valorizzare la passione per il teatro, ma c’è di più... Ma c’è di più. C’è una prospettiva, ovvero quella di stimolare e sostenere la crescita morale, culturale e sociale dell’individuo, attraverso ogni espressione di arte, promuovendone la diffusione in ogni sua forma. Nel proprio nome IDEEINSCENA richiama la capacità di metterci… la fantasia, partendo dalle doti personali fino al saper lavorare insieme per creare progetti condivisi e non isolate imprese. Il tutto con il valore aggiunto dell’entusiasmo, della motivazione, dell’innovazione.'
                    linkAbilitato={true}
                    testoBottone={'Chi siamo'}
                    linkTo={'/about'}
                />

                <hr className="custom" />
                <section className={rowClass}>
                    <section className={colClass}>
                        {containers.map((container) => (
                            <Container
                                key={container.id}
                                classname="p-4 custom-box"
                                showImage={true}
                                imgSrc={container.imgSrc}
                                titolo={container.Titolo}
                                testo={container.Testo}
                                testoSecondario={container.TestoSecondario}
                                linkAbilitato={container.linkAbilitato}
                                testoBottone={container.testoBottone}
                                navigateTo={container.navigateTo}
                            />
                        ))}
                        <br /><br />
                        {galleries.map((gallery) => (
                            <ImageGallery
                                key={gallery.id}
                                title={<span>{gallery.title}<br />{gallery.subtitle}</span>}
                                testo={gallery.text}
                                imageLinks={gallery.imageLinks}
                            />
                        ))}
                        <br /><br />
                        <Container
                            showImage={true}
                            imgSrc="https://firebasestorage.googleapis.com/v0/b/sito-ideeinscena.appspot.com/o/LOGO%20FondazioneGiancarloPallavicini.png?alt=media&token=eaeb84c6-19ba-44ab-95ab-79e8ccb5a04d"
                            titolo="PARTNER PER IL SOCIALE"
                            testo={`
                                IDEEINSCENA ha un PARTNER PER IL SOCIALE: la Fondazione Giancarlo Pallavicini onlus,
                                con valori e progetti condivisi.
                            `}
                            linkAbilitato={false}
                        />
                    </section>
                    <section className="col-md-4 sidebar">
                        {!isTablet && <MainSidebar />}
                    </section>
                </section>
            </main>
            {!isTablet && <BackupButton />}
            <Footer />
        </div>
    );
};

export default MainPage;
