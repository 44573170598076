import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { doc, getDoc } from 'firebase/firestore';
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../Config/firebaseConfig';

import Navbar from '../Components/Navbar';
import Loading from '../Components/Loading';
import ImageGallery from '../Components/ImageGallery';
import SpettacoloSidebar from '../Components/SpettacoloSidebar';
import BackupButton from '../Components/BackupButton';
import Footer from '../Components/Footer';

import ReactGA from "react-ga4";

const Evento = () => {
    const location = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    
    const [evento, setEvento] = useState(null);
    const [loading, setLoading] = useState(true);
    const [imageUrls, setImageUrls] = useState([]);
    const [error, setError] = useState(null);

    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    useEffect(() => {
        const fetchEvento = async () => {
            try {
                const eventoRef = doc(db, 'Eventi', id);
                const eventoSnap = await getDoc(eventoRef);

                if (eventoSnap.exists()) {
                    const eventoData = eventoSnap.data();
                    
                    // Verifica se isBozza è true e fai il redirect
                    if (eventoData.isBozza === true) {
                        navigate('/nondisponibile');
                        return; // Esci dalla funzione se fai il redirect
                    }

                    setEvento(eventoData);

                    // Recupera e mostra le immagini dalla cartella se esiste
                    if (eventoData.FolderPath) {
                        const folderRef = ref(storage, eventoData.FolderPath);
                        const res = await listAll(folderRef);
                        const urls = await Promise.all(
                            res.items.map(async (itemRef) => {
                                try {
                                    const url = await getDownloadURL(itemRef);
                                    return url;
                                } catch (urlError) {
                                    console.error("Error getting download URL for image:", urlError);
                                    return null;
                                }
                            })
                        );
                        setImageUrls(urls.filter(url => url !== null));
                    } else {
                        console.log("FolderPath is not defined in the document.");
                    }
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching evento:", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchEvento();

        return () => {
            document.title = 'Eventi Culturali'; // Reset the title on cleanup
        };
    }, [id, navigate]);

    useEffect(() => {
        if (evento) {
            // Aggiorna il titolo della pagina
            document.title = evento.Titolo;

            // Invia la pagina a Google Analytics
            ReactGA.send({
                hitType: "pageview",
                page: location.pathname,
                title: evento.Titolo,
            });
        }
    }, [evento, location.pathname]); 

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error loading event details. Please try again later.</div>;
    }

    if (!evento) {
        return <div>No event data available.</div>;
    }

    return (
        <div>
            <Navbar />
            <main id="main">
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>{evento.Titolo}</h2>
                        </div>
                    </div>
                </section>

                <section id="blog" className="blog">
                    <div className="container" data-aos="fade-up">
                        <div className="row">
                            <div className="col-lg-8 entries">
                                <article className="entry entry-single">
                                    <div className="entry-img">
                                        {evento.LinkImg && <img src={evento.LinkImg} alt={evento.Titolo} className="img-fluid" />}
                                    </div>
                                    <div className="entry-meta">
                                        <ul>
                                            <li className="d-flex align-items-center"><i className="bi bi-person"></i>{evento.Autore}</li>
                                            <li className="d-flex align-items-center"><i className="bi bi-clock"></i><time dateTime={evento.Data}>{evento.Data}</time></li>
                                        </ul>
                                    </div>
                                    <div className="entry-content">
                                        <h3>{evento.Sottotitolo}</h3>
                                        <p>{evento.Testo1}</p>

                                        <br /><br /><br />

                                        {evento.LinkYTB ? (
                                            <iframe
                                                width="560"
                                                height="315"
                                                src={evento.LinkYTB}
                                                title="YouTube video player"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                referrerPolicy="strict-origin-when-cross-origin"
                                                allowFullScreen
                                            ></iframe>
                                        ) : null}
                                    </div>
                                </article>
                            </div>
                            <div className="col-lg-4">
                                {!isTablet &&
                                    <div id="sidebar" className="sidebar">
                                        <SpettacoloSidebar />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="container pers" data-aos="fade-up">
                        <div className="section-title">
                            <h2>{evento.Titolo}</h2>
                            <p>GALLERIA FOTOGRAFICA</p>
                        </div>
                    </div>

                    <div id="gallerySection" className="container">
                        <ImageGallery title="" imageLinks={imageUrls} />
                    </div>
                </section>
            </main>
            {!isTablet && <BackupButton />}
            <Footer />
        </div>
    );
};

export default Evento;
